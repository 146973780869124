module.exports = {
    getDomainSuffix: function() {
        return document.domain.replace(/.*\./, '');
    },
    addGeoClass: function () {
        let domainSuffix = this.getDomainSuffix();
        let htmlElement = document.getElementsByTagName("html")[0];
        htmlElement.className = htmlElement.className ? htmlElement.className + " " + domainSuffix : domainSuffix;
    },
    getUCTraderURI() {
        switch (this.getDomainSuffix()) {
            case 'ru':
                return 'https://app.uctrader.unicreditbank.ru';
            case 'hu':
                return 'https://app.uctrader.unicreditbank.hu';
            case 'ro':
                return 'https://app.uctrader.unicredit.ro/auth/login?lang=ro';
            default:
                return 'https://uctrader.unicreditgroup.eu'
        }
    }
};