var cookies = require('browser-cookies');
var GeoHelper = require('./GeoHelper');

function getPageLocale() {
    var pageUrl = location.pathname.replace(/^\/|\/$/g, '');
    var pageUrlSplit = pageUrl.split(/\/|.html/g);
    let pageLocale = pageUrlSplit[pageUrlSplit.length - 1] || pageUrlSplit[pageUrlSplit.length - 2];
    return pageLocale && locales[pageLocale].locale;
}

function getDefaultLocale() {
    return getPageLocale() || getPrimaryLocale();
}

var locales = {};
function getPrimaryLocale() {
    let locale = GeoHelper.getDomainSuffix();
    if (locales[locale] !== undefined) {
        return locale;
    }
    return 'en';
}

module.exports = {
    setLocales(inLocales) {
        locales = inLocales;
    },
    getLocales() {
        return locales
    },
    getPrimaryLocale: getPrimaryLocale,
    getLocale: function () {
        let locale = cookies.get('locale');
        if (locales[locale] !== undefined) {
            return locale;
        }
        return getDefaultLocale();
    },
    setLocale: function (newLocale) {
        if (locales[newLocale] !== undefined) {
            cookies.set('locale', newLocale, {expires: 90})
        }
        else {
            throw "Locale <" + newLocale + "> is not set up for this application.";
        }
    },
    switchLocale: function () {
        let locale = this.getLocale();
        window.location.replace(locale);
    }
};
