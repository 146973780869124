module.exports = {
    'en': {
        locale: 'en',
        description: 'English',
    }
    ,
    'de': {
        locale: 'de',
        description: 'Deutsche'
    }
    ,
    'ru': {
        locale: 'ru',
        description: 'русский'
    }
    ,
    'ro': {
        locale: 'ro',
        description: 'Română'
    }
    ,
    'hu': {
        locale: 'hu',
        description: 'Magyar'
    }
    ,
    'it': {
        locale: 'it',
        description: 'Italian'
    }
    ,
    'bg': {
        locale: 'bg',
        description: 'Bulgarian'
    }
    ,
    'sr': {
        locale: 'sr',
        description: 'Serbian'
    }
    ,
    'hr': {
        locale: 'hr',
        description: 'Croatian'
    }
};
